<footer class="site-footer" id="footer">
  <div class="footer-content">
    <div class="contact-info">
      <h3>Contact Us</h3>
      <p>Email: basavaraja@heyidb.com</p>
      <p>Phone: +918971126783</p>
    </div>
    <!-- <div class="social-links">
      <h3>Follow Us</h3>
      <ul>
        <li>
          <a href="" target="_blank">
            <img src="https://cdn4.iconfinder.com/data/icons/miu-hexagon-flat-social/60/twitter-hexagon-social-media-32.png" alt="" matTooltip="Twitter" matTooltipPosition="above">
          </a>
        </li>
        <li>
          <a href="" target="_blank">
            <img src="https://cdn3.iconfinder.com/data/icons/free-social-icons/67/Untitled-11-32.png" alt="" matTooltip="LinkedIn" matTooltipPosition="above">
          </a>
        </li>
      </ul>
    </div> -->
  </div>
  <div class="copyright">
    <p>&copy; 2023 HeyiDB. All Rights Reserved.</p>
    <p><a href="https://heyidb.com/emojicandymergeprivacypolicy.txt" target="_blank" style="color: white">Privacy Policy</a></p>
  </div>
</footer>
